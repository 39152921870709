<template>
  <SuccessComponent flow="add-funds"></SuccessComponent>
</template>
<script>
import SuccessComponent from './SuccessLayout.vue'

export default {
  components: {
    SuccessComponent,
  },
}
</script>
